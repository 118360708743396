import React from "react";
import i18n from "#translate/i18n";
export default function CompanyInformation({ companyInformation }) {
  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_data.corporate_name')}:
        </span>{" "}
        {companyInformation.corporate_name}
      </p>
      <p><span className="font-bold">{i18n.t('userProfile.company_data.cnpj')}: </span>{companyInformation.cnpj}</p>
      {companyInformation.type_corporate && (
        <p>
          <span className="font-bold">
            {i18n.t('userProfile.company_data.type_corporate.title')}:
          </span>{" "}
          {i18n.t(`userProfile.company_data.type_corporate.${ companyInformation.type_corporate }`)}
        </p>
      )}
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_data.state_registration')}:
        </span>{" "}
        {companyInformation.state_registration || i18n.t('userProfile.company_data.exempt')}
      </p>
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_data.municipal_registration')}:
        </span>{" "}
        {companyInformation.municipal_registration || i18n.t('userProfile.company_data.exempt')}
      </p>
      {companyInformation.collect_iss && (
        <p>
          {i18n.t(`userProfile.company_data.collect_iss.${companyInformation.collect_iss}`)}
        </p>
      )}
    </div>
  );
}