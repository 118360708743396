import React, { useEffect, useState } from "react";
import i18n from "#translate/i18n";
import { useFormContext } from "react-hook-form";
import InputText from "../../InputText";
import SelectInput from "../../SelectInput";
import DivisionTitle from "../../DivisionTitle";
import fetchCorporateTypes from "#requests/fetchCorporateTypes";
import InputMaskedText from "#components/formComponents/InputMaskedText";
import CheckboxInput from "#components/formComponents/CheckboxInput";

export default function CompanyInformationFields() {
  const model = "company_information";
  const [corporateTypes, setCorporateTypes] = useState(['']);
  const [disabledMunicipalRegistration, setDisabledMunicipalRegistration] = useState(false)
  const [disabledStateRegistration, setDisabledStateRegistration] = useState(false)
  const { setValue, trigger, getValues } = useFormContext()
  useEffect(() => {
    fetchCorporateTypes()
      .then((parsedResponse) => {
        setCorporateTypes(parsedResponse["corporate_types"]);
    });
  }, []);

  const handleChange = event => {
    const field = event.target.name
    const result = event.target.value.replace(/[^0-9!@#¨$%^&*)(+=._-]+/gi, '')
    setValue(field, result)
  }

  const handleChecked = (event) => {
    const checked = event.target.checked
    setValue(`${model}.empty_municipal_registration`, checked)
    setDisabledMunicipalRegistration(checked)
    if (checked) { setValue(`${model}.municipal_registration`, "") }
    trigger([`${model}.municipal_registration`])
  };

  const handleStateRegistrationChecked = (event) => {
    const checked = event.target.checked
    setValue(`${model}.empty_state_registration`, checked)
    setDisabledStateRegistration(checked)
    if (checked) { setValue(`${model}.state_registration`, "") }
    trigger([`${model}.state_registration`])
  }

  return (
    <>
      <DivisionTitle title={i18n.t("company_data")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <InputText
          model={model}
          name="corporate_name"
          title={i18n.t("company_information.corporate_name")}
          requiredField={true}
          className='w-full'
        />

        <InputMaskedText
          model={model}
          name="cnpj"
          title={i18n.t("company_information.cnpj")}
          mask="99.999.999/9999-99"
          requiredField={true}
          maskChar={null}
        />

        <SelectInput
          model={model}
          name="type_corporate"
          title={i18n.t("company_information.type_corporate")}
          options={ corporateTypes }
          requiredField={true}
          className='w-full'
        />

        <div>
          <InputText
            model={model}
            name="state_registration"
            title={i18n.t("company_information.state_registration")}
            requiredField={true}
            className='w-full'
            onChange={handleChange}
            disabled={disabledStateRegistration || getValues(`${model}.empty_state_registration`)}
          />

          <CheckboxInput
            model={model}
            name="empty_state_registration"
            label={i18n.t('company_information.empty_state_registration')}
            checkboxInputDivClassName={'h-10 w-10 pt-[5px] pl-3'}
            id={`${model}.empty_state_registration`}
            labelClassName={'text-sm font-normal text-blue-gray font-sans'}
            onClick={handleStateRegistrationChecked}
            inputDisabled={false}
          />
        </div>
        <div>
          <InputText
            model={model}
            name="municipal_registration"
            title={i18n.t("company_information.municipal_registration")}
            requiredField={true}
            className='w-full'
            onChange={handleChange}
            disabled={disabledMunicipalRegistration || getValues(`${model}.empty_municipal_registration`)}
          />

          <CheckboxInput
            model={model}
            name="empty_municipal_registration"
            label={i18n.t('company_information.empty_municipal_registration')}
            checkboxInputDivClassName={'h-10 w-10 pt-[5px] pl-3'}
            id={`${model}.empty_municipal_registration`}
            labelClassName={'text-sm font-normal text-blue-gray font-sans'}
            onClick={handleChecked}
            inputDisabled={false}
          />
        </div>

        <SelectInput
          model={model}
          name="collect_iss"
          title={i18n.t(`company_information.collect_iss`)}
          options={[true, false]}
          requiredField={true}
          className='w-full'
          divClassName={"flex flex-col justify-start"}
        />
      </div>
    </>
  );
}
