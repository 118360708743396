import { translations as navigationTranslations } from './pt-BR/navigation'
import { reportsTranslations } from './pt-BR/reportsTranslations'
import { translations as userProfileTranslations } from './pt-BR/userProfile'
import { translations as timeInCompanyTranslations } from './pt-BR/timeInCompany'
import { translations as peopleListingTranslations } from './pt-BR/peopleListing'
import { translations as drawerResponsesTranslations } from './pt-BR/drawerResponses'
import { translations as timelineEventsTranslations } from './pt-BR/timelineEvents'
import { translations as firstGuestAccessTranslation } from './pt-BR/firstGuestAccess'
import { translations as vacationTranslations } from './pt-BR/vacation'
import { translations as yearEndBonusTranslations } from './pt-BR/yearEndBonus'
import { translations as dismissalTranslations } from './pt-BR/dismissal'
import { translations as loginTranslations } from './pt-BR/login'
import { translations as customizedFilterSheetTranslations } from './pt-BR/customizedExportSheet.jsx'
import { translations as homePage } from "./pt-BR/homePage.jsx"
import { translations as tooltipTranslations } from './pt-BR/tooltip.jsx'
import { translations as bonusRewardTranslations } from './pt-BR/bonusReward.jsx'
import { translations as expenseAssistanceTranslations } from './pt-BR/expenseAssistance.jsx'
import { translations as workLeaveTranslations } from './pt-BR/workLeave.jsx'
import { translations as salaryReadjustmentTranslations } from './pt-BR/salaryReadjustment.jsx'
import { translations as allowancestTranslations } from './pt-BR/allowances.jsx'
import { translations as onboardingTranslations } from './pt-BR/onboarding.jsx'
import { translations as notificationTranslations } from './pt-BR/notification.jsx'

const messages = {
  "pt-BR": {
    translations: {
      navigation: { ...navigationTranslations },
      reportDashboard: { ...reportsTranslations },
      timeInCompany: { ...timeInCompanyTranslations },
      userProfile: { ...userProfileTranslations },
      peopleListing: { ...peopleListingTranslations },
      drawerResponses: { ...drawerResponsesTranslations },
      timelineEvents: { ...timelineEventsTranslations },
      firstGuestAccess: { ...firstGuestAccessTranslation },
      vacation: { ...vacationTranslations },
      yearEndBonus: { ...yearEndBonusTranslations },
      login: { ...loginTranslations },
      user_dismissal: { ...dismissalTranslations },
      customizedDataExport: { ...customizedFilterSheetTranslations },
      homePage: { ...homePage },
      tooltip: { ...tooltipTranslations },
      onboarding: { ...onboardingTranslations },
      notification: { ...notificationTranslations },
      ...bonusRewardTranslations,
      ...expenseAssistanceTranslations,
      ...workLeaveTranslations,
      ...salaryReadjustmentTranslations,
      ...allowancestTranslations,
      yup: {
        schemas: {
          mixed: {
            required: "*Preenchimento obrigatório",
            oneOf: "*Selecione uma das opções",
          },
          string: {
            email: "Não é um formato de e-mail válido",

          },
          number: {
            positive: "Deve ser um número positivo",
          },
          date: {
            min_date: "O término deve ser posterior à data de início",
          },
        },
        forms: {
          errors: {
            social_name: "Só pode conter letras e espaços",
            email_or_password: "Senha ou email inválidos",
            password_confirmation: "As senhas não são iguais",
            terms_of_use: "É necessário aceitar os termos de uso",
            required: "*Preenchimento obrigatório",
            required_number: "Número é obrigatório",
            required_warning: "*Preenchimento obrigatório",
            required_attribute: "O campo ${path} é obrigatório",
            select_option: "*Selecione uma das opções",
            email: "Não é um formato de e-mail válido",
            invalid_format: "deve ser preenchido corretamente",
            invalid_format_attribute:
              "${path} deve ser preenchido corretamente",
            invalid_ddd: "DDD inválido",
            invalid_number: "Número inválido",
            email_corporate: "Domínio inválido",
            cnpj_format: "Formato do CNPJ inválido",
            character_number: "deve ter até {{number}} caracteres",
            contact_caracter_number: "deve ser preenchido completamente",
            unique_active_bank_account:
              "Apenas uma conta bancária pode estar ativa",
            active_bank_account:
              "É necessário ter uma conta ativa cadastrada",
            upload_failure: "Falha no carregamento. Tente novamente",
            invalid_username: "Usuário inválido",
            github_error: "Preenchimento inválido",
            unique_name_descendant: "O nome do dependente já consta na lista.",
            cpf_invalid: "CPF inválido",
            adjourning_motivation_filled: "O motivo do término deve ser removido.",
          },
          labels: {
            full_name: "Nome completo",
            email: "E-mail",
          },
        },
      },
      removalConfirmation: {
        text: 'Deseja prosseguir?',
        quit: 'Desistir',
        confirm: 'Remover',
      },
      successMessage: {
        check: "Símbolo check",
      },
      empty_file: "Documento não fornecido",
      occupation: {
        developer: "Pessoa desenvolvedora",
      },
      status: {
        active: "Ativo",
        inactive: "Inativo",
      },
      has_social_name: {
        has_social_name: "Possui nome social?",
        select_option: "Selecione uma das opções",
        true: "Sim",
        false: "Não",
      },
      marital_status: {
        marital_status: "Estado Civil",
        single: "Solteiro(a)",
        married: "Casado(a)",
        divorced: "Divorciado(a)",
        widowed: "Viúvo(a)",
      },
      pronouns: {
        pronouns: "Pronomes",
        select_option: "Selecione uma das opções",
        he_him: "Ele/Dele",
        she_her: "Ela/Dela",
        they_them: "Elu/Delu",
        text: 'e saiba mais sobre a linguagem neutra',
        link: 'Clique aqui'
      },
      sharing_home_with: {
        sharing_home_with: "Quantas pessoas vivem com você?",
        subtitle: "Incluindo você",
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        13: 13,
        14: 14,
        15: 15,
      },
      race_color: {
        race_color: "Qual sua etnia?",
        yellow: "Amarela",
        white: "Branca",
        brown: "Pardo",
        black: "Preta",
        indigenous: "Indígena",
        without_answer: "Prefiro não responder",
      },
      medical_follow_up: "Você faz atualmente algum acompanhamento médico que considera importante compartilhar conosco?",
      special_medical_condition: "Existe alguma necessidade especial ou condição médica que você gostaria de nos informar?",
      human_children: {
        human_children: "Você tem filhos humanos?",
        select_option: "Selecione uma das opções",
        true: "Sim",
        false: "Não",
      },
      lgbtqia_plus: {
        lgbtqia_plus: "Pertence à comunidade LGBTQIAPN+?",
        select_option: "Selecione uma das opções",
        yes: "Sim",
        no: "Não",
        no_answer: "Prefiro não responder",
      },
      pictures: {
        title: "Fotos",
        alt: {
          face: "Foto de Rosto",
          body: "Foto de Corpo",
        },
        type: {
          face: "Foto de Rosto",
          body: "Foto de Corpo",
        },
      },
      kinship: {
        kinship: "Parentesco",
        aunt: "Tia",
        brother: "Irmão",
        sister: "Irmã",
        cousin: "Primo(a)",
        daughter: "Filha",
        father: "Pai",
        husband: "Marido",
        mother: "mâe",
        son: "Filho",
        uncle: "Tio",
        wife: "Esposa",
        others: "Outros",
      },
      users: {
        title: "Pessoas",
        message_error: "Algo deu errado. Tente novamente mais tarde",
      },
      requests: {
        network_error: "Network response was not ok.",
      },
      tablePeople: {
        persons: "Pessoas",
        clients: "Cliente",
        salary: "Salário",
        status: "Status",
        service_time: "Temp. Casa",
        last_readjustment: "Últ. Reajuste",
        available_days_off: "Folgas Disp.",
        and: "e",
        year: "ano",
        years: "anos",
        months: "meses",
        month: "mês",
        days: "dias",
        actions: "Ações",
        balloon: "Balão",
        pencil: "Lápis para editar",
        three_points: "Menu três pontos",
      },
      welcome: {
        title: "Seja bem-vinde, {{name}}",
        subtitle: "Vamos começar?",
        message:
          " Escolha e selecione um dos campos para começar a nos\
          contar um pouco mais sobre você.  Você pode retornar a esta tela quando\
          quiser para iniciar o preenchimento dos outros campos.",
        addData:
          "Preencher dados de cadastro como endereço e contatos,\
          dados bancários, dados da sua empresa, entre outras informações\
          importantes para termos as informações necessárias sobre\
          sua chegada à Rebase.",
        addDescription:
          " Quem é você? Do quê você gosta? Aqui você irá acessar\
          uma seção de perguntas que irão nos ajudar a saber suas\
          preferências e assim, nos aproximarmos mais.",
        addFiles:
          " Hora de carregar alguns arquivos. Para esta parte, prepare\
          os seguintes documentos: RG, CPF, Comprovante de Residência,\
          Comprovante Plano Saúde (se tiver), MEI/CNPJ.",
        imgData: "Preencher dados de cadastro",
        imgDescription: "Saber mais informações do usuário",
        imgFiles: "Adicionar documentos pessoais",
      },
      FillProgress: {
        searchPlaceHolder: "Pesquise por todo sistema",
        title: "🎊 Olá, que bom ter você conosco! ️🎉",
        subtitle: "Próximos passos",
        label: "COMPLETO",
        description:
          "Clique nos cards abaixo para completar o envio dos seus dados. \
        Aproveite também para ler algumas informações importantes para o \
        início da sua trajetória.",
        registrationData: {
          title: "DADOS CADASTRAIS",
          description:
            "Complete seu perfil com dados de endereço, contatos, dados da \
          sua empresa, entre outras informações.",
        },
        hobbiesAndInterests: {
          title: "HOBBIES E INTERESSES",
          description:
            "Do que você gosta? Conte sobre seus interesses para nos conhecermos melhor.",
        },
        documents: {
          title: "DOCUMENTOS PESSOAIS",
          description:
            "Hora de enviar alguns arquivos. Prepare uma cópia do RG, \
          CPF, MEI/CNPJ e outros.",
        },
      },
      invite: {
        title: "Nome Completo",
        placeHolder: {
          currency: "R$ 0",
        },
        messageForm: {
          error: {
            contacts: {
              corporate_email: {
                'já_está_em_uso': 'Já existe um usuário cadastrado com o <strong>e-mail corporativo</strong> informado',
              },
            },
          },
        },
      },
      searchInput: {
        placeholder: "Pesquise por nome ou e-mail",
        imgMagnifier: "Lupa",
      },
      searchRangeDate: {
        placeholder: "Entrada na Empresa",
        imgCalendar:
          "Busca da entrada do colaborador na empresa em um período determinado",
      },
      invite_img: "Convidar um novo rebaser",
      dropdown_arrow_img: "abrir ou fechar dropdown",
      add_new_person: "Cadastrar nova pessoa",
      monthYear: {
        placeholder: "MM/AAAA",
        imgCalendar: "Seleciona mês e ano",
      },
      noPerson: {
        imgMagnifier: "sem colaborador",
        message:
          "Oooops... Não encontramos nenhuma pessoa com os filtros utilizados.",
      },
      headSideBar: {
        imgHamburguerMenu: "hamburguer menu",
      },
      userDetails: {
        message_error: "Algo deu errado. Tente novamente mais tarde",
      },
      userForm: {
        new_user_title: "Nova Pessoa",
        edit_user_title: "Edição de cadastro",
        cancel_user_button: "Cancelar",
        cancel_edit_user_title: "Cancelar edição",
        cancel_edit_user_confirmation: "Deseja cancelar a edição do cadastro de <strong>{{ name }}</strong>?",
        give_up_cancelation_edit_user_button: "Continuar editando",
        cancel_new_user_title: "Cancelar cadastro",
        cancel_new_user_confirmation: "Deseja cancelar o cadastro de uma nova pessoa?",
        give_up_cancelation_new_user_button: "Continuar cadastrando",
        messageForm: {
          error: {
            basic_server_error: "Preenchimento inválido",
            contacts: {
              corporate_email: {
                'já_está_em_uso': 'Já existe um usuário cadastrado com o <strong>e-mail corporativo</strong> informado',
              },
            },
            descendants: {
              "healthcare.was_not_previously_removed_from": "Um ou mais dependentes possuem Prêmios Saúde cadastrados. Remova o(s) benefício(s) para seguir com a remoção deste(s) dependente(s)."
            }
          }
        },
        buttons: {
          save: "Salvar",
          saving: "Salvando",
          cancel: "Cancelar",
        }
      },
      userMenu: {
        delete_user_confirm: "Você tem certeza que deseja remover o usuário?",
        deleted_user: "Usuário removido com sucesso",
      },
      buttons: {
        new_person: "Nova Pessoa",
        new_project: "Adicionar projeto",
        new_contact: "Novo contato",
        remove_project: "Remover projeto",
        remove_contact: "Remover contato",
        logout: "Sair",
        registration: "Cadastro",
        moreInfos: "Saber mais",
        upload: "Subir arquivos",
        new_bank_account: "Adicionar conta",
        remove_bank_account: "Remover conta",
        removeFile: "Remover arquivo",
        new_emergency_contact: "Novo contato de emergência",
        remove_emergency_contact: "Remover contato de emergência",
        register_new_person: "Cadastrar nova pessoa",
        invite_new_person: "Convidar nova pessoa",
        export: "Exportar",
        actions: "Ações",
        defaultText: "Salvar",
        processingText: "Processando",
        cancel: "Cancelar",
        tickSymbol: "Adicionando",
        rightIcon: "Seta para direita",
        saving: 'Salvando',
        processing: "Processando",
        export_people_data: "Exportar dados",
        export_people_data_picture: "Imagem exportar dados",
        exporting: 'Exportando',
      },
      validations: {
        is_required: "*Preenchimento obrigatório",
      },
      address: {
        address: "Endereço de residência",
        delivery_address: "Endereço para entrega",
        street: "Rua",
        zip_code: "CEP",
        neighborhood: "Bairro",
        house_number: "Número",
        city: "Cidade",
        state: "Estado",
        reference_point: "Ponto de referência",
        complement: "Complemento",
        country: "País",
        alternative: {
          title: "Precisa definir outro endereço para entrega?",
          true: "Sim",
          false: "Não",
        },
        zip_code_error: '* CEP inválido',
        empty_complement: 'Não possui complemento.'
      },
      delivery_address: {
        address: "Endereço para entrega",
        street: "Rua",
        zip_code: "CEP",
        neighborhood: "Bairro",
        house_number: "Número",
        city: "Cidade",
        state: "Estado",
        reference_point: "Ponto de referência",
        complement: "Complemento",
        country: "País",
      },
      personal_preference: {
        detail_preferences: "Preferências Detalhadas",
        sport_preferences: "Esportes",
        foods_and_beverages: "Comes e Bebes",
        preferred_sport: "Qual seu esporte favorito?",
        preferred_club:
          "Você tem algum time do coração? Se sim, conta qual é...",
        preferred_videogame:
          "Você joga videogame? Se sim, quais são seus jogos preferidos?",
        preferred_movies_series:
          "Quais são seus filmes, séries e desenhos favoritos? Por favor, liste um de cada",
        preferred_bands_singers:
          "Quais são os seus cantores e bandas preferidas?",
        best_trip: "Qual foi a melhor viagem da sua vida?",
        wish_list: {
          title: "Cite coisas que gosta de ganhar",
          subtitle: "Use sua imaginação, um dia você pode receber uma surpresa!",
        },
        personal_collections:
          "Você coleciona algo? Se sim, conte um pouco mais...",
        likes_social_media:
          "Você curte gerar e compartilhar conteúdo nas redes sociais? Se sim, aqui é o espaço para você deixar o seu @",
        about_you:
          "Fale sobre você! Dica: O que gostaria que o time da Rebase soubesse de você? Conta um pouco da sua trajetória, o que gosta de fazer nas horas vagas, etc.",
        favorite_color: "Qual sua cor favorita?",
        hobbies: {
          hobbies: "Escolha aqui seus hobbies",
          hobby_list: "Hobbies selecionados",
          remove_button_alt: "Botão com X para remover hobby",
          icon: "Ícone para",
          not_found:
            "Este hobby não está disponível no momento. Tente pesquisar outro.",
          not_found_alt_text: "Lupa grande em fundo cinza",
        },
        preferred_foods:
          "Que tipo de comida você gosta de comer fora e em casa? Cite alguns exemplos, no mínimo 2 de cada.",
        preferred_beers:
          "Gosta de cerveja? Se sim, quais tipos e marcas preferidas?",
        preferred_juices: "Quais sucos você mais gosta de tomar?",
        preferred_fruits: "Quais suas frutas favoritas?",
        preferred_beverages:
          "De maneira geral, quais são suas bebidas preferidas para beber em eventos ou passeios que costuma fazer?",
        avoided_foods:
          "Tem algum tipo de alimento que você não gosta ou não pode consumir? Quais?",
        forms_error: {
          presence: "*Preenchimento obrigatório",
        }
      },
      day_night_preference: {
        day_night_preference: "Você é uma pessoa mais diurna ou noturna?",
        select_option: "Selecione uma das opções",
        day: "Diurna",
        night: "Noturna",
      },
      alternative: {
        true: "Sim",
        false: "Não",
      },
      clothing: {
        clothing: "Vestuário",
        t_shirt_size: {
          title: "Qual seu tamanho de camiseta?",
          P: "P",
          M: "M",
          G: "G",
          GG: "GG",
          X1: "X1",
          X2: "X2",
          X3: "X3",
          modal: {
            title: "Precisando de referência de tamanho?",
            description:
              "Consulte a tabela acima com tamanhos de referência para medidas de Camiseta.",
          },
          alt: "Tamanho da camiseta",
        },
        // sweatshirt_size: {
        //   title: "Qual seu tamanho de moletom?",
        //   subtitle: "Ver referência",
        //   P: "P",
        //   M: "M",
        //   G: "G",
        //   GG: "GG",
        //   GG1: "GG1",
        //   GG2: "GG2",
        //   GG3: "GG3",
        //   XG: "XG",
        //   forty_six: "46",
        //   forty_eight: "48",
        //   fifty: "50",
        //   fifty_two: "52",
        //   fifty_four: "54",
        //   modal: {
        //     title: "Precisando de referência de tamanho?",
        //     description:
        //       "Consulte a tabela acima com tamanhos de referência para medidas de Moletom.",
        //   },
        //   alt: "Tamanho do moletom",
        // },
        // pants_size: {
        //   title: "Qual seu tamanho de calça?",
        //   P: "P",
        //   M: "M",
        //   G: "G",
        //   GG: "GG",
        //   modal: {
        //     title: "Precisando de referência de tamanho?",
        //     description:
        //       "Consulte a tabela acima com tamanhos de referência para medidas de Calça.",
        //   },
        //   alt: "Tamanho da calça",
        // },
        shoe_size: {
          title: "Qual seu tamanho de calçado?",
          size33_or34: "33/34",
          size35_or36: "35/36",
          size37_or38: "37/38",
          size39_or40: "39/40",
          size41_or42: "41/42",
          size43_or44: "43/44",
          size45_or46: "45/46",
          modal: {
            title: "Precisando de referência de tamanho?",
            description:
              "Consulte a tabela acima com tamanhos de referência para medidas de Calçado.",
          },
          alt: "Tamanho do calçado",
        }
      },
      health_notes: {
        title:
          "Possui alguma particularidade?",
        subtitle: "Ex: Doenças crônicas, restrições alimentares ou o que mais você julgar importante",
        select_option: "Selecione uma das opções",
        true: "Sim",
        false: "Não",
      },
      general_notes: {
        general_notes: "Observações gerais",
        allergies: "Possui alguma alergia?",
      },
      beneficiary: {
        beneficiary: "Beneficiário",
        is_itself: "O próprio",
        other: "Outro",
      },
      account_type: {
        account_type: "Tipo de conta",
        checking_account: "Conta Corrente",
        savings: "Poupança",
        salary_account: "Conta salário",
        legal_account: "Conta jurídica",
      },
      account_status: {
        active: "Ativa",
        inactive: "Inativa",
      },
      bank_account: {
        cpf_or_cnpj: "CPF/CNPJ do títular",
        bank_id: "Código e nome do banco",
        agency: "Agência",
        account: "Conta",
        account_type: "Tipo de Conta",
        pix_key: "Chave pix da conta",
        beneficiary_name: "Nome do beneficiário",
        rg_of_holder: "Número do RG do titular",
        account_status: "Status da conta",
        not_found: "Não foram encontrados resultados para esta busca.",
      },
      personal_document: {
        alt: {
          rg_front: "RG frente",
          rg_back: "RG verso",
          cpf_front: "CPF frente",
          cpf_back: "CPF verso",
          residence_proof: "Comprovante de residência",
          service_contract: "Contrato",
          cnpj_card: "Cartão CNPJ",
          lgpd_contract: "LGPD",
          image_usage_agreement: "Uso de Imagem",
          health_card: "Carteira do plano de saúde",
        },
        titles: {
          documents: "Documentos Pessoais",
          residence_proof: "Comprovante de residência",
          mei_cnpj: "Documentos Jurídicos",
          health_card: "Comprovante plano de saúde",
        },
        descriptions: {
          documents:
            "Para complementar seu cadastro, envie uma foto nítida da frente e verso do seu RG e CPF.",
          residence_proof:
            "Envie uma foto nítida de um comprovante do seu endereço de residência (Conta de água, luz, telefone e etc).",
          mei_cnpj:
            "Envie uma foto nítida ou pdf do Contrato de Prestação de Serviços, CNPJ, Termo LGPD e Termo de uso de imagem.",
          health_card:
            "Caso possua plano de saúde envie uma foto da carteirinha do seu plano.",
        },
        document_type: {
          rg_front: "RG Frente",
          rg_back: "RG Verso",
          cpf_front: "CPF Frente",
          cpf_back: "CPF Verso",
          residence_proof: "Comprovante",
          service_contract: "Contrato",
          cnpj_card: "CNPJ",
          lgpd_contract: "LGPD",
          image_usage_agreement: "Uso de Imagem",
          health_card: "Carteira do Plano",
        },
      },
      errors: {
        invalid_email_or_password: "Senha ou email inválidos",
        social_name_format: "Só pode conter letras e espaços",
        required_email: "O campo e-mail é obrigatório",
        required_name: "O campo nome é obrigatório",
        required_password: "O campo senha é obrigatório",
        invalid_format: "deve ser preenchido corretamente",
        email_corporate: "Domínio invalido",
        cnpj_format: "Formato do cnpj invalido",
        character_number: "deve ter até {{number}} caracteres",
        contact_caracter_number: "deve ser preenchido completamente",
        unique_active_bank_account:
          "Apenas uma conta bancária pode estar ativa",
        upload_failure: "Falha no carregamento. Tente novamente",
      },
      signup: {
        signup: "Criar conta",
        name: "Nome completo *",
        email: "Email *",
        password: "Senha *",
        password_confirmation: "Confirme a senha *",
        back_to_login: "Voltar a tela de login",
      },
      company_information: {
        corporate_name: "Razão social da empresa",
        cnpj: "CNPJ da sua empresa",
        state_registration: "Inscrição estadual",
        type_corporate: "Tipo de empresa",
        municipal_registration: "Inscrição municipal",
        collect_iss: "Recolhe ISS?",
        owner_data: "Iguais aos meus dados pessoais",
        is_itself: "Sim",
        other: "Outro",
        email: "E-mail",
        zip_code: "CEP",
        street: "Rua",
        neighborhood: "Bairro",
        city: "Cidade",
        state: "Estado",
        house_number: "Número",
        complement: "Complemento",
        country: "País",
        empty_municipal_registration: "Marcar como Isenta",
        empty_state_registration: "Marcar como Isenta"
      },
      company_history_record: {
        project_name: "Nome do projeto",
        client_id: "Parceiro",
        occupation: "Função",
        squad_name: "Nome do time (squad)",
        start_date: "Início",
        end_date: "Término",
        adjourning_motivation: "Motivo do término",
        tools_and_permissions: "Ferramentas/acessos",
        on_going: "Trabalha atualmente neste projeto",
      },
      has_equipment: {
        has_equipment: "Você tem notebook das empresas Rebase, Campus Code ou Brainn.co?",
        true: "Sim",
        false: "Não"
      },
      company_history: {
        company: "Empresa em que atua",
        company_invite: "Empresa em que irá atuar",
        work_field: "Atuação",
        initial_remuneration: "Remuneração inicial",
        current_remuneration: "Remuneração atual",
        mattermost_username: "Usuário Mattermost",
        github_user: "Usuário Github",
        github_placeholder: "Ex: https://github.com/seu-usuario-aqui",
        service_tag: "Numeração Service Tag (etiqueta localizada na traseira do equipamento)",
        company_assets: "Equipamentos recebidos da Rebase",
        client_assets: "Equipamentos recebidos de clientes",
      },
      company: {
        BRAINN: "Brainn",
        CAMPUSCODE: "Campus Code",
        REBASE: "Rebase",
      },
      work_field: {
        DEV: "Pessoa desenvolvedora",
        COMMS: "Comunicação",
        MKT: "Marketing",
        SPE: "Pessoa especialista",
        HR: "Time Pessoas",
        UX: "UX",
        FINANCES: "Financeiro",
        THIRD_PARTY: "Terceirizada",
        INTERN: "Estagiário",
        PROJECTS: "Projetos",
        ALAN: "Sócio - Alan Batista",
        EDERSON: "Sócio - Ederson de Lima",
        JOAO: "Sócio - João Almeida"
      },
      type_corporate: {
        MEI: "MEI - Microempreendedor individual",
        EI: "EI - Empresário Individual",
        EIRELI: "EIRELI - Empresa Individual de Responsabilidade Limitada",
        LTDA: "LTDA - Sociedade Limitada",
        SLU: "SLU - Sociedade Limitada Unipessoal",
        sociedade_simples: "Sociedade Simples",
        sociedade_anonima: "Sociedade Anônima"
      },
      collect_iss: {
        true: "Sim",
        false: "Não",
      },
      owner_data: {
        is_itself: "Sim",
        other: "Não",
      },
      modal: {
        actions: {
          close: "Fechar",
        },
      },
      reference_modal_title: "Ver referências",
      modal_user_register: {
        cancel: "Cancelar",
        new_rebaser: "Cadastrar novo Rebaser",
        text: "O sistema encaminhará um e-mail para os endereços informados abaixo para que a pessoa inicie o processo de admissão.",
        add_new_rebaser: "Adicionar novo Rebaser",
      },
      invite_success: {
        sent: "convite enviado com sucesso",
        heading: "Convite enviado com sucesso",
        text: "Um e-mail foi encaminhado para o(a) novo Rebaser.",
        close: "Botão para fechar mensagem de convite enviado com sucesso",
      },
      select_file: "Selecione o arquivo",
      change_image: "Alterar imagem",
      leave: "Sair",
      terms_of_use: "Termos de uso",
      in_construction: "Em construção",
      full_name: "Nome completo",
      full_name_subtitle:
        "Sem abreviações, será utilizado apenas para fins administrativos da Rebase",
      social_name: "Informe seu nome social",
      social_name_placeholder: "Preencha com seu nome social",
      nickname: "Como gostaria de ser chamado?",
      nickname_placeholder: "Como quer que o time te chame",
      birth_date: "Data de nascimento",
      gender_identity: {
        title: "Com qual gênero se identifica?",
        cis: "Cisgênero",
        trans: "Transgênero",
        non_binary: "Pessoa não binária",
        prefer_not_to_answer: "Prefiro não dizer",
        describe_myself: "Prefiro me autodescrever",
        text: 'e saiba mais sobre identidade de gênero',
        link: 'Clique aqui'
      },
      gender_description: "Autodescrição:",
      rg_number: "Número do RG",
      cpf_number: "Número do CPF",
      male: "Masculino",
      home: "Home",
      personal_data: "Dados pessoais",
      socioeconomic_data: "Dados socioeconômicos",
      hiring_date: "Data de início na Rebase",
      bank_data: "Dados bancários",
      company_info: "Informações da empresa",
      general_infos: "Observações gerais",
      years: "Anos",
      reference_point: "Referência",
      contact_details: "Dados de contato",
      emergency_contact: "Contatos de emergência",
      email: "E-mail",
      personal_email: "E-mail pessoal",
      personal_email_placeholder: "Informe seu e-mail pessoal",
      corporate_email: "E-mail corporativo",
      corporate_email_subtitle: "Campus Code, Rebase, Brainn",
      corporate_email_placeholder: "Informe seu e-mail do trabalho",
      contact_name: "Nome do contato",
      relationship: "Relacionamento com o contato",
      landline: "Fixo",
      landline_phone: "Telefone fixo",
      contact_subtitle: 'Contato principal',
      emergency_contact_subtitle: 'Contato de emergência',
      phone: "Telefone celular",
      emergency_landline_phone: "Telefone fixo de contato de emergência",
      emergency: "Emergência",
      health_plan: "Plano de saúde",
      notes: "Observações",
      company_data: "Dados empresariais",
      personal: "Dados do proprietário da empresa",
      company_address: "Endereço da empresa",
      history: "Histórico de projetos",
      general: "Dados gerais",
      observations: "Observações",
      asset_control: "Controle de Ativos",
      graduate_class_id: "Turma Campus Code",
      placeholders: {
        select_option: "Selecione uma das opções",
        currency_input: "R$ 0",
        company_history: {
          company_assets:
            "Liste os equipamentos recebidos no kit onboarding. Ex: notebook, monitor, mouse e etc.",
          client_assets:
            "Liste aqui os equipamentos recebidos do cliente do projeto que você faz parte. Ex: Monitor, fone de ouvido, tag de vpn e etc.",
        },
        company_history_record: {
          tools_and_permissions: "Ex: Zoom",
          adjourning_motivation: "Ex: Fim de projeto",
        },
        hobbies: {
          search_box: "Busque por uma atividade que você goste",
        },
        personal_preference: {
          wish_list: "Ex: objetos, bebidas, comidas, ações...",
          likes_social_media: "Ex: Instagram @aprendendodev",
        },
        preferred_beverages: "Ex: refrigerantes, vinhos...",
        general_notes:
          "Ex: Diagnóstico de TDAH",
        allergies: "Ex: Alergia a camarão",
        emergency_contacts: {
          contact_name: "Sem abreviações",
          relationship: "Ex. pai, mãe, parceira(o), amiga(o), tia(o), avó...",
          email: "ederson@rebase.com.br",
          observations: "Informe alguma informação importante sobre este contato."
        },
        special_medical_condition: "Ex. Não possui necessidades especiais.",
        medical_follow_up: "Ex. Faz acompanhamento psiquiátrico."
      },
      reward_type: {
        select_option: "Selecione uma das opções",
        quintalk: "Quintalk",
        live_coding: "Live Coding",
        competitions: "Concursos",
        party_kit: "Kit Festa",
        onboarding: "Auxílio Onboarding"
      },
      payment_type: {
        select_option: "Selecione uma das opções",
        benefits_card: "Cartão de benefícios",
        invoice: "Nota fiscal",
      },
      user_menu: {
        bonus_reward_button: "Adicionar premiação",
        expense_assistance_button: "Adicionar ajuda de custo",
        salary_readjustment_button: "Reajuste salarial",
        add_allowances_button: "Adicionar benefícios",
      },
      descendants: {
        full_name: "Nome do(a) filho(a)",
        full_name_placeholder: "Sem abreviações",
        birthdate: "Data de nascimento",
        forms_error: {
          presence: "O preenchimento deste campo é obrigatório",
          date_error: "A data de nascimento não pode ser após a data de hoje",
        },
      },
      rangeDate: {
        placeholder: "Data início - Data fim",
      }
    },
  },
};

export { messages };
