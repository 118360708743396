import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const companyInformationSchema = Yup.object().shape({
  corporate_name: Yup.string().required(),
  cnpj: Yup.string().required(),
  owner_data: Yup.string().required(),
  email: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  zip_code: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  street: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  neighborhood: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  city: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  state: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  house_number: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  country: Yup.string()
    .when("owner_data", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  empty_complement: Yup.boolean(),
  complement: Yup.string()
  .when(["owner_data", "empty_complement"], {
    is: (owner_data, empty_complement) => (owner_data === 'other' && (empty_complement === false || empty_complement === 'false')),
    then: (schema) => schema.required(),
  }),
  type_corporate: Yup.string().required(),
  empty_municipal_registration: Yup.boolean(),
  municipal_registration: Yup.string().when('empty_municipal_registration', {
    is: (empty_municipal_registration) => (empty_municipal_registration === false || empty_municipal_registration === 'false'),
    then: (schema) => schema.required(),
  }),
  empty_state_registration: Yup.boolean(),
  state_registration: Yup.string().when('empty_state_registration', {
    is: (empty_state_registration) => (empty_state_registration === false || empty_state_registration === 'false'),
    then: (schema) => schema.required(),
  }),
  collect_iss: Yup.string().required(),
});

export default companyInformationSchema;
