/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react"
import i18n from "#translate/i18n"
import moment from "moment"

import Menu from "#components/listings/person/Menu"
import Status from "#components/listings/person/Status"
import TimeInCompany from "#components/user/profile/TimeInCompany";
import RoundPicture from "#components/user/RoundPicture";

import NoAvatarIcon from "#images/no-avatar.svg"
import TrendingIcon from "#images/trending_up.svg"

import { UserContext } from "#providers/UserContextProvider"

export default function Person({
  person,
  selectAll,
  unselectedAll,
  handleRowSelection,
  openMenu,
  setOpenMenu,
  row,
  peopleLength,
}) {
  const { handleDrawersOpen, handleDrawersClose, setMenuOrigin } = useContext(UserContext)

  const [selected, setSelected] = useState(false)
  const lastReadjustmentFeatureToggle = false

  const handleSelection = (event) => {
    let checked = event.target.checked
    setSelected(checked)
    handleRowSelection(person.id, checked)
  }

  const formattedSalary = person.salary ? Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(person.salary) : '-'

  const lastReadjustment = person.last_readjustment ? person.last_readjustment.replace(/-/g, '/') : '-'

  const birthdate = moment(person?.birth_date).format('DD/MM/YYYY')
  const hiringDate = moment(person?.hiring_date).format('DD/MM/YYYY')
  const workField = person?.work_field ? i18n.t(`peopleListing.workFields.${person.work_field}`) : '-'

  const avatar = person.face_picture?.url ? <RoundPicture divStyle="w-12 h-12 mx-auto mt-0.5" imgStyle='object-cover' src={person.face_picture.url} /> :
    <img src={NoAvatarIcon} alt="Avatar" className="mt-0.5" />

  useEffect(() => {
    if (unselectedAll && !selectAll) {
      setSelected(!unselectedAll)
      handleRowSelection(person.id, selectAll)
      return
    }

    if (selectAll && !unselectedAll) {
      setSelected(selectAll)
      handleRowSelection(person.id, selectAll)
    }
  }, [selectAll, unselectedAll])

  function closingMenu() {
    handleDrawersClose({ drawersToClose: [], clearData: false })
    setMenuOrigin['people'](true)
    setMenuOrigin['profile'](false)
  }

  const fixedTd = 'sticky bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)] whitespace-nowrap pl-5 box-border'

  const notFixedTd = `shadow-[inset_0_-1px_0_rgba(212,219,223,1)] pl-5 ${selected ? 'animate-personSelection' : ''} box-border`
  if(!person.id) { return }

  return (
    <>
      <tr
        id={`row_${row}`}
        className={`h-16 shadow-table-row w-full`}
      >
        <td className={`${fixedTd} z-[1000] left-0 text-center w-16 ${selected ? 'animate-personSelection' : ''}`}>
          <input id={`checkbox_${row}`} type="checkbox" checked={selected} onChange={handleSelection} className="w-4 h-4 rounded border-slate-300" />
        </td>
        <td className={`${fixedTd} z-[1000] left-16 w-[383.88px] ${selected ? 'animate-personSelection' : ''}`}>
          <button onClick={() => { handleDrawersOpen(person, 'profile') }} className="flex items-center h-full w-full text-left my-2">
            <div id={`avatar_${row}`} className="flex items-center justify-center bg-white rounded-full drop-shadow-md w-12 h-12 mr-3">
              {avatar}
            </div>
            <div className="mt-[3px]">
              <span className="font-sans font-semibold text-sm text-default-gray-2">{person.presentedName}</span><br />
              <span className="font-sans font-medium text-xs text-slate-400">{person.corporate_email || i18n.t('peopleListing.noEmail')}</span>
            </div>
          </button>
        </td>
        <td className={`${notFixedTd} w-[120.83px]`}>
          <div id={`client_${row}`}>
            <img src={person.current_client_logo_url} alt="" className="max-h-14 mt-2 max-w-full" />
          </div>
        </td>
        <td className={`${notFixedTd} w-[121.39px]`}>
          <span className="font-sans font-normal text-sm text-default-gray-2">{birthdate} </span>
        </td>
        <td className={`${notFixedTd} w-[200.84px]`}>
          <span className="font-sans font-normal text-sm text-default-gray-2">{workField} </span>
        </td>
        <td className={`${notFixedTd} w-[176.13px]`}>
          <Status person={person} showMenu={true} />
        </td>
        <td className={`${notFixedTd} w-[121.92px]`}>
          <span className="font-sans font-normal text-sm text-default-gray-2">{hiringDate} </span>
        </td>
        <td className={`${notFixedTd} w-[167.78px]`}>
          <span className="font-sans font-normal text-sm text-default-gray-2">
            <TimeInCompany
              months={person.number_of_months_in_the_company}
            />
          </span>
        </td>
        <td className={`${notFixedTd} w-[135.55px]`}>
          <span className="font-sans font-normal text-sm text-default-gray-2">{formattedSalary}</span>
        </td>
        <td className={`shadow-[inset_0_-1px_0_rgba(212,219,223,1)] ${notFixedTd} w-[136.95px] ${selected ? 'animate-personSelection' : ''} z-10`}>
          <div className="flex flex-row">
            <span className="font-sans font-normal text-sm text-default-gray-2 grow">{lastReadjustment}</span>
            {
              lastReadjustment != '-' && lastReadjustmentFeatureToggle && <>
                <img src={TrendingIcon} alt="" className="w-5 px-1 ml-3 flex-none" />
                <span className="text-support-positive font-sans font-normal text-sm">38%</span>
              </>
            }
          </div>
        </td>
        <td className={`${fixedTd} right-0 max-w-max p-5 ${openMenu === row ? 'z-30' : "z-10"} ${selected ? 'animate-personSelection' : ''} w-[82.73px]`} id="menu-person" >
          <div id={`action_menu_${row}`} className='w-9'>
            <Menu
              person={person}
              closingMenu={closingMenu}
              peopleLength={peopleLength}
              row={row}
              setOpenMenu={setOpenMenu}
              openMenu={openMenu}
            />
          </div>
        </td>
      </tr>
    </>
  )
}
