const translations = {
  calendar_icon: "ícone calendário",
  client_icon: "ícone cliente",
  company_icon: "ícone empresa",
  email_icon: "ícone e-mail",
  landline_icon: "ícone telefone fixo",
  github_icon: "ícone github",
  mattermost_icon: "ícone mattermost",
  menu_icon: "ícone opções",
  mobile_icon: "ícone celular",
  return_icon: "fechar perfil",
  work_field_icon: "ícone profissão",
  about: "Sobre",
  info: "Info. Gerais",
  bank_company: "Banco e empresa",
  documents: "Documentos",
  received_assets: "Ativos recebidos",
  attachments: "Anexos",
  historic: "Histórico",
  remuneration: "Remuneração",
  about_me: "Sobre mim",
  history_records: "Histórico de projetos",
  reason: "Motivo do encerramento",
  tools: "Ferramentas/Acessos",
  hobbies: "Hobbies",
  preferences: "Preferências",
  prefer_movies: "Filmes, séries e desenhos favoritos",
  prefer_singers: "Cantores e bandas favoritas",
  prefer_gifts: "Presentes e coleções",
  share_social_media: "Conteúdo para redes sociais",
  favorite_color: "Cor favorita",
  prefer_sport: "Esportes favoritos e time do coração",
  prefer_games: "Games",
  favorite_food: "Comidas favoritas",
  avoid_food: "Alimentos que não gosta",
  favorite_beverages: "Bebidas favoritas",
  best_trip: "Viagem favorita",
  routine: {
    title: "Rotina",
    routineOption: {
      day: "Diurna",
      night: "Noturna",
    },
  },
  personal_data: "Dados pessoais",
  missing_data: "Dado não preenchido",
  no_notebook: "Não possui notebook",
  dependents: "Dependentes",
  address: "Endereço",
  contact_data: "Dados de contato",
  restrictions: "Restrições",
  full_name: "Nome completo",
  social_name: "Nome social",
  nickname: "Gostaria de ser chamado por",
  sharing_home_with: "Reside com",
  children: "Filhos (as)",
  birthdate: "Dt. Nasc",
  peopleWithCount_one: "{{count}} pessoa",
  peopleWithCount_other: "{{count}} pessoas",
  home_address: "Endereço residencial",
  delivery_address: "Endereço de entrega",
  reference: "Referência",
  shirt: "Camiseta",
  pants: "Calça",
  sweatshirt: "Blusa de Moletom",
  shoe: 'Calçado',
  health_notes: "Possui alguma particularidade? (Ex: Doenças crônicas, restrições alimentares ou o que mais você julgar importante)",
  general_notes: "Observações gerais",
  allergies: "Possui alguma alergia",
  medical_monitoring: {
    medical_follow_up: "Acompanhamento médico",
    special_medical_condition: "Necessidades especiais ou condição médica",
  },
  principal_contact: "Contato principal",
  emergency_contact: "Contato de emergência",
  race_color: {
    yellow: "Amarela",
    white: "Branca",
    indigenous: "Indígena",
    brown: "Parda",
    black: "Preta",
    without_answer: "Prefiro não responder",
  },
  marital_status: {
    single: "Solteiro(a)",
    married: "Casado(a)",
    divorced: "Divorciado(a)",
    widowed: "Viúvo(a)",
  },
  lgbtqia_plus: {
    yes: "Pertence a comunidade LGBTQIAPN+",
    no: "Não pertence a comunidade LGBTQIAPN+",
    no_answer: "Prefiro não responder",
  },
  gender_identity: {
    cis: "Cisgênero",
    trans: "Transgênero",
    non_binary: "Pessoa não binária",
    prefer_not_to_answer:"Prefiro não dizer",
  },
  observations: "Observações",
  missingObservations: 'Nenhuma observação foi adicionada.',
  clothing: {
    clothing: "Vestuário",
    t_shirt_size: {
      P: "P",
      M: "M",
      G: "G",
      GG: "GG",
      X1: "X1",
      X2: "X2",
      X3: "X3",
    },
    shoe_size: {
      size33_or34: "33/34",
      size35_or36: "35/36",
      size37_or38: "37/38",
      size39_or40: "39/40",
      size41_or42: "41/42",
      size43_or44: "43/44",
      size45_or46: "45/46"
    }
  },
  bank_data: {
    bank_data: "Dados bancários",
    bank: "Banco",
    agency: "Agência",
    account: {
      checking_account: "Conta corrente",
      savings: "Conta poupança",
      salary_account: "Conta salário",
      legal_account: "Conta jurídica",
    },
    pix_key: "Chave PIX",
    beneficiary_name: "Beneficiário",
    rg_of_holder: "RG",
    cpf_of_holder: "CPF/CNPJ",
  },
  company_data: {
    company_data: "Informações da empresa",
    corporate_name: "Razão social",
    cnpj: "CNPJ",
    type_corporate: {
      title: "Tipo de empresa",
      MEI: "MEI",
      EI: "EI",
      EIRELI: "EIRELI",
      LTDA: "LTDA",
      SLU: "SLU",
      sociedade_simples: "Sociedade Simples",
      sociedade_anonima: "Sociedade Anônima"
    },
    state_registration: "Inscrição Estadual",
    municipal_registration: "Inscrição Municipal",
    exempt: "Isenta",
    collect_iss: {
      true: "Com recolhimento de ISS",
      false: "Sem recolhimento de ISS",
    }
  },
  company_address: "Endereço da empresa",
  landline: "Fixo",
  mobile: "Celular",
  company_historic: {
    title: 'Histórico na',
    subtitle: 'Histórico de eventos',
    REBASE: 'Rebase',
    BRAINN: 'Brainn',
    CAMPUSCODE: 'Campus Code',
    hiring_date: 'Data de início na empresa',
    graduate_class_name: 'Turma Campus Code',
    initial_remuneration: 'Remuneração inicial',
    without_graduate_class: 'Não participou de turma da Campus Code',
    current_remuneration: 'Remuneração atual',
  },
  documents_tab: {
    documents_data: {
      rg_of_holder: "RG: ",
      cpf_of_holder: "CPF: ",
      cnpj: "CNPJ: "
    },
    received_assets_data: {
      notebook: "Notebook",
      service_tag: "Numeração Service Tag",
      without_asset: "Não possui"
      // company_assets: "Equipamentos Rebase",
      // client_assets: "Equipamentos cliente",
    },
    documents_attachments: {
      face_picture: 'Foto de rosto',
      body_picture: 'Foto de corpo',
      service_contract: 'Contrato de prestação de serviços',
      cnpj_card: 'Cartão CNPJ',
      lgpd_contract: 'Termo LGPD',
      image_usage_agreement: 'Termo de uso de imagem',
      residence_proof: 'Comprovante de endereço',
      rg_front: 'RG frente',
      rg_back: 'RG verso',
      cpf_front: 'CPF frente',
      cpf_back: 'CPF verso',
      healthcare: 'Carterinha plano de saúde',
      work_leave: 'Comprovante de afastamento',
    },
  },
  remuneration_tab: {
    remuneration: {
      title: 'Remuneração',
      moreOptions: 'Mais opções',
    },
    active_allowances: {
      title: 'Benefícios ativos',
      menu: {
        details: 'Ver detalhes do benefício',
        remove: 'Remover benefício',
        edit: 'Editar benefício'
      },
      meal: {
        title: 'Auxílio alimentação',
        menu: {
          moreOptions: 'active meal options',
        },
      },
      home_office: {
        title: 'Auxílio home office',
        menu: {
          moreOptions: 'active home_office options',
        },
      },
      year_end_bonus: {
        title: 'Bônus de fim de ano',
        menu: {
          moreOptions: 'active year_end_bonus options',
        },
      },
      vacation: {
        title: 'Descanso remunerado',
        menu: {
          moreOptions: 'active vacation options',
        },
      },
      healthcare: {
        title: 'Prêmio saúde',
        beneficiaries_one: '{{count}} Beneficiário',
        beneficiaries_other: '{{count}} Beneficiários',
        menu: {
          moreOptions: 'active vacation options',
        },
      },
      psychological_support: {
        title: 'Auxílio psicológico',
        monthly_sessions_one: '{{count}} sessão mensal',
        monthly_sessions_other: '{{count}} sessões mensais',
        menu: {
          moreOptions: 'active psychological support options',
        },
      },
      parental_leave: {
        title: 'Presença parental',
        menu: {
          moreOptions: 'active vacation options',
        },
      },
      no_active_allowances: 'Não há benefícios ativos',
    },
    latest_rewards: {
      title: 'Últimos prêmios recebidos',
      reward_titles: {
        quintalk: 'Quintalk',
        live_coding: 'Live Coding',
        competitions: 'Concursos',
        party_kit: 'Kit/cestas',
        onboarding: 'Auxílio onboarding',
      },
      menu: {
        moreOptions: 'Mais opções',
        details: 'Ver detalhes do prêmio',
        remove: 'Remover prêmio',
        edit: 'Editar prêmio'
      },
      no_latest_rewards: 'Não há prêmios recebidos',
    },
    expense_assistances: {
      title: 'Últimas ajudas de custo recebidas',
      menu: {
        moreOptions: 'Mais opções',
        details: 'Ver detalhes da ajuda de custo',
        remove: 'Remover ajuda de custo',
        edit: 'Editar ajuda de custo'
      },
      frequency: {
        single: "Pontual",
        recurring: "Recorrente",
      },
      no_expense_assistance: 'Não há ajudas de custo recebidas',
    },
    remunerations: {
      month: {
        January: 'Jan',
        February: 'Fev',
        March: 'Mar',
        April: 'Abr',
        May: 'Mai',
        June: 'Jun',
        July: 'Jul',
        August: 'Ago',
        September: 'Set',
        October: 'Out',
        November: 'Nov',
        December: 'Dez',
      },
      legends: {
        salary: 'Remuneração',
        healthcare: 'Saúde',
        meal: 'Alimentação',
        home_office: 'Home Office',
        year_end_bonus: 'Bônus fim de ano',
        vacation: 'Desc. rem.',
      },
      xAxis: 'Meses',
      yAxis: 'Valores'
    },
    remuneration_evolution:{
      title: 'Evolução da remuneração',
      subtitle: 'Remuneração e benefícios',
    }
  },
  about_tab: {
    on_going_project: "atualmente",
  }
}

export { translations }
